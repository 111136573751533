<template>
  <q-card class="column" :style="{ height: `${sHeight}px` }">
    <q-card-section class="bg-primary">
      <div class="row items-center">
        <q-btn
          flat
          color="white"
          size="sm"
          icon="arrow_back_ios"
          @click="router.go(-1)"
        ></q-btn>
        <span class="text-white text-bold">Pilih Barang</span>
      </div>
      <!-- <q-input
        outlined
        class="bg-white q-mt-md rounded-borders"
        placeholder="Cari Barang"
        v-model="data.searchTerm"
      >
        <template v-slot:prepend>
          <q-icon name="search" />
        </template>
      </q-input> -->
    </q-card-section>
    <q-scroll-area class="l-grow">
      <q-list separator bordered>
        <q-item class="row q-gutter-x-sm justify-start items-center q-pa-md">
          <q-img
            :src="`./img/beras.jpeg`"
            fit="container"
            style="width: 100px; height: 100px"
          ></q-img>
          <div>
            <div class="text-subtitle">{{ data.beras.desc }}</div>
            <div class="text-h6 text-bold q-mb-md">
              {{ rupiah(data.beras.price) }}
            </div>
            <div class="row items-center q-mt-sm" style="">
              <q-btn
                unelevated
                dense
                icon="remove"
                size="sm"
                :color="data.beras.qty < 1 ? 'grey-5' : 'primary'"
                :disable="data.beras.qty < 1"
                @click="data.beras.qty--"
              ></q-btn>
              <q-input
                v-model="data.beras.qty"
                type="number"
                style="width: 50px"
                input-class="text-center text-bold"
                dense
                borderless
              >
              </q-input>
              <q-btn
                unelevated
                dense
                icon="add"
                size="sm"
                color="primary"
                @click="data.beras.qty++"
              ></q-btn>
            </div>
          </div>
        </q-item>
        <q-item class="row q-gutter-x-sm justify-start items-center q-pa-md">
          <q-img
            :src="`./img/minyak.jpg`"
            fit="container"
            style="width: 100px; height: 100px"
          ></q-img>
          <div>
            <div class="text-subtitle">{{ data.minyak.desc }}</div>
            <div class="text-h6 text-bold q-mb-md">
              {{ rupiah(data.minyak.price) }}
            </div>
            <div class="row items-center q-mt-sm" style="">
              <q-btn
                unelevated
                dense
                icon="remove"
                size="sm"
                :color="data.minyak.qty < 1 ? 'grey-5' : 'primary'"
                :disable="data.minyak.qty < 1"
                @click="data.minyak.qty--"
              ></q-btn>
              <q-input
                v-model="data.minyak.qty"
                type="number"
                style="width: 50px"
                input-class="text-center text-bold"
                dense
                borderless
              >
              </q-input>
              <q-btn
                unelevated
                dense
                icon="add"
                size="sm"
                color="primary"
                @click="data.minyak.qty++"
              ></q-btn>
            </div>
          </div>
        </q-item>
      </q-list>

      <q-inner-loading :showing="isLoadingData">
        <q-spinner-gears size="50px" color="primary" />
      </q-inner-loading>
    </q-scroll-area>
    <q-card-section class="q-pa-none">
      <q-card-section class="row justify-between items-center shadow-up-2">
        <div class="">
          <div>Total Harga</div>
          <div class="f14 text-weight-bolder">{{ rupiah(total) }}</div>
        </div>
        <q-btn
          unelevated
          label="Pembayaran"
          color="primary"
          :disable="data.minyak.qty == 0 && data.beras.qty == 0"
          @click="checkOut"
        ></q-btn>
      </q-card-section>
    </q-card-section>
  </q-card>
</template>

<script>
import useBasic from "@/composables/useBasic";
import { computed, watch } from "vue";
export default {
  setup() {
    const {
      reactive,
      ref,
      onMounted,
      route,
      router,
      loadThis,
      isLoadingData,
      $http,
      moment,
      waitThis,
      $q,
      thousand,
      rupiah,
      sHeight,
    } = useBasic();

    let data = reactive({
      beras: {
        name: "Beras",
        price: 0,
        desc: "Beras Sania Premium 5 KG",
        qty: 0,
      },
      minyak: {
        name: "Minyak",
        price: 0,
        desc: "Minyak Goreng Sania 2 L",
        qty: 0,
      },

      price: {},

      searchTerm: "",

      multiplier: 0,
      limitMinyak: 0,
    });

    onMounted(async () => {
      loadThis(getPrice);
    });

    let getqty = () => {
      if (localStorage.getItem("minyak")) {
        data.minyak.qty = parseInt(localStorage.getItem("minyak"));
      }

      if (localStorage.getItem("beras")) {
        data.beras.qty = parseInt(localStorage.getItem("beras"));
      }
    };

    let total = computed(() => {
      return (
        parseInt(data.beras.price) * data.beras.qty +
        parseInt(data.minyak.price) * data.minyak.qty
      );
    });

    watch(
      () => data,
      (newValue, oldValue) => {
        if (data.minyak.qty + data.beras.qty >= 10) {
          data.minyak.price = data.price.minyak_agen;
          data.beras.price = data.price.beras_agen;
        } else {
          data.minyak.price = data.price.minyak;
          data.beras.price = data.price.beras;
        }

        if (newValue.minyak.qty < 0) {
          data.minyak.qty = 0;
        }

        if (newValue.beras.qty < 0) {
          data.beras.qty = 0;
        }
      },
      { deep: true }
    );

    let getPrice = async () => {
      let resp = await $http.get("/price");
      data.price = resp.data;

      getqty();
    };

    let checkOut = () => {
      localStorage.setItem("beras", data.beras.qty);
      localStorage.setItem("minyak", data.minyak.qty);
      router.push("/payment");
    };

    return {
      data,
      total,
      sHeight,
      checkOut,
      isLoadingData,
      router,
      thousand,
      rupiah,
    };
  },
};
</script>
<style lang="scss">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
